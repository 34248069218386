import { useEffect, useState, useRef } from "react";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import DraggableLetter from "./DraggableLetter";
import DragToBox from "./DragToBox";
import { usePreview } from "react-dnd-preview";
import AppButton from "../../Components/Common/AppButton";
import { ROUND_INFO, ROUND_INFO_NEW, getRandomLettersForGame } from "../../Constants/GamesArena/MiniScrabble";
import { AnimatePresence, motion } from "framer-motion";
import Loader from "../../Components/PageComponents/Loader";
import { Timer } from "../../Components/Games/SpellBee/GameComponents/CountdownTimer";
import DarkModal from "../../Components/Common/DarkModal";
import  listOfWords from '../../assets/scrabble-words.json'; 
import { InGameNotificationPopup } from "../../Components/Games/SpellBee/InGameNotificationPopup";


const MyPreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { item, style } = preview;
  return (
    <div
      className="flex bg-primary-yellow rounded-2xl text-black relative h-[70px] w-[70px] text-[32px] font-medium justify-center items-center uppercase"
      style={style}
    >
      {item.letter}
      <div className="absolute top-1 right-2 text-[18px]">{item.score}</div>
    </div>
  );
};

const ScrabbleRoundGameBoard = ({ gameState, updateGameState, submitGame, isTrialGame, currentActiveIndex, totalQuestions, currentActiveQuestion, roundsPerQuestion }) => {

  const NUMBER_OF_ROUNDS = roundsPerQuestion ?? 4;
  const [filledLetters, setFilledLetters] = useState([]);
  const [activeRound, setActiveRound] = useState(1);
  const [submittingAnswer, setSubmittingAnswer] = useState(true);
  const [checkingAnswer, setCheckingAnswer] = useState(false);
  const [isAnsValid, setIsAnsValid] = useState(false);
  const [localScore, setLocalScore] = useState(0);
  const [localAnswer, setLocalAnswer] = useState("");
  const [proceeding, setProceeding] = useState(false);
  const [localActiveRound, setLocalActiveRound] = useState(1);
  const [showImage, setShowImage] = useState(false);
  const [showWaitScreen, setShowWaitScreen] = useState(false);
  const [pauseTimer, setPauseTimer] = useState(false);
  const [showRoundInfoPopup, setShowRoundInfoPopup] = useState(true);
  const [showInGameNotificationPopup, setShowInGameNotificationPopup] = useState(false);
  const [localSubmittedAnswer, setLocalSubmittedAnswer] = useState({});

  const scoreRef = useRef(0);
  const attemptsRef = useRef(0);
  const correctAttemptsRef = useRef(0);
  const localScoreRef = useRef(0);
  const timerRef = useRef(null);

  useEffect(() => {
    const currentActiveRound = 1;
    setActiveRound(currentActiveRound);
    setFilledLetters(
      Array.from({ length: ROUND_INFO_NEW?.[currentActiveRound - 1]?.length }).fill({ letter: 0, score: 0 })
    );

    setSubmittingAnswer(true);
    setCheckingAnswer(false);
    setIsAnsValid(false);
    setLocalScore(0);
    setLocalAnswer("");
    setProceeding(false);
    setLocalActiveRound(1);
    setShowImage(false);
    setShowWaitScreen(false);
    setPauseTimer(false);



  }, [currentActiveQuestion]);


  useEffect(() => {
    setFilledLetters(
      Array.from({ length: ROUND_INFO_NEW[activeRound - 1].length }).fill({ letter: 0, score: 0 })
    );
    setShowRoundInfoPopup(true);
    setPauseTimer(true);
    setTimeout(() => {
      setShowRoundInfoPopup(false);
      setPauseTimer(false);
    }, 3000);
  }, [activeRound]);

  useEffect(() => {
    //if filledLetters is not empty, check if all letters are filled and if yes, set submittingAnswer to false
    if (filledLetters.length > 0 && filledLetters.every(({ letter }) => letter !== 0)) {
      setSubmittingAnswer(false);
      checkWord();
    }
  }, [filledLetters]);

  useEffect(() => {
    if (showImage) {
      setTimeout(() => {
        setShowImage(false);
      }, 2000);
    }
  }, [showImage]);

  useEffect(() => {
    const img1 = new Image();
    img1.src = "/Assets/Icons/tick-green-icon.svg";
    const img2 = new Image();
    img2.src = "/Assets/Icons/cross-icon.svg";
  }, []);

  const handleDrop = (dropResult) => {
    if (dropResult) {
      const { letter, score, index, clickedIndex } = dropResult;
      const newFilledLetters = [...filledLetters];
      newFilledLetters[index] = { letter, score, clickedIndex };
      setFilledLetters(newFilledLetters);
    }
  };

  const addLetterByClicking = (letter, score, clickedIndex) => {
    const emptyIndex = filledLetters.findIndex(({ letter }) => letter === 0);
    if (emptyIndex !== -1) {
      const newFilledLetters = [...filledLetters];
      newFilledLetters[emptyIndex] = { letter, score, clickedIndex };
      setFilledLetters(newFilledLetters);
    }
  }

  const checkWord = async (submit=false) => {
    if (filledLetters.some(({ letter }) => letter === 0)) {
      setLocalSubmittedAnswer({
        answer: "",
        isAnsValid: false,
      });
      return;
    }
    setCheckingAnswer(true);
    setPauseTimer(true);
    const answer = filledLetters.map(({ letter }) => letter).join("");
    const resp = await fetch(
      `https://api.dictionaryapi.dev/api/v2/entries/en/${answer}`
    );
    let score = 0;
    if (resp.ok) {
      score = filledLetters.reduce((acc, { score }) => acc + score, 0);
    }
    setLocalAnswer(answer);
    setIsAnsValid(resp.ok);
    if(submit && resp.ok){
      setLocalSubmittedAnswer({
        answer: answer,
        isAnsValid: resp.ok,
      });
    }
    // localScoreRef.current = score;
    // setLocalScore(score);
    setCheckingAnswer(false);
    setPauseTimer(false);
    setShowImage(true);
    return resp.ok;
  };

  const removeLetter = (idx) => {
    const newFilledLetters = [...filledLetters];
    newFilledLetters[idx] = { letter: 0, score: 0 };
    setFilledLetters(newFilledLetters);
  };

  const handleBackspaceClick = () => {
    if (filledLetters.every(({ letter }) => letter === 0)) return;
    const newFilledLetters = [...filledLetters].reverse();
    const idx = newFilledLetters.findIndex(({ letter }) => letter !== 0);
    if (idx !== -1) {
      newFilledLetters[idx] = { letter: 0, score: 0, clickedIndex: -1 };
    }
    setFilledLetters(newFilledLetters.reverse());
  };

  const renderKeysSection = () => {
    return (
      <div className="grid place-items-center">
        <div className="flex flex-wrap gap-x-3 gap-y-[18px] w-full justify-center max-w-[390px]">
          {currentActiveQuestion.letters?.map(({ letter, score }, index) => {
            return (
              <DraggableLetter
                letter={letter}
                score={score}
                handleDrop={handleDrop}
                handleAddLetter={addLetterByClicking}
                filledLetters={filledLetters}
                clickedIndex={index}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const calculateAndUpdateScore = (isAnsValid = false) => {
    const timeLeft = timerRef.current;
    let roundScore = 0;
    if (isAnsValid) {
      filledLetters.forEach(({ score }) => {
        roundScore += score;
      });
    }

    scoreRef.current += roundScore;
    // setLocalScore(score);
    localScoreRef.current = roundScore;
    attemptsRef.current += 1;
    if (isAnsValid) {
      correctAttemptsRef.current += 1;
    }

    const responseWords = gameState.responseWords || [];
    const timeTaken = ROUND_INFO_NEW[activeRound - 1].time - timeLeft;
    // Update the specific index with new data
    const responseWordsData = {
      answer: localAnswer,
      score: localScoreRef.current,
      isValid: isAnsValid,
      wordSize: ROUND_INFO_NEW[activeRound - 1].length,
      letters: currentActiveQuestion.letters,
      possibleWord: getSuggestedWords(),
      timeTaken,
    };

    responseWords.push(responseWordsData);

    const dataToUpdate = {
      score: scoreRef.current,
      attempts: attemptsRef.current,
      correctAttempts: correctAttemptsRef.current,
      responseWords,
    };
    

    if (!isTrialGame) {
      updateGameState(dataToUpdate, true);
    }
  }

  const submitAnswer = async () => {
    const isAnsValid = await checkWord(true);
    setShowWaitScreen(true);
    setShowInGameNotificationPopup(true);
    setPauseTimer(true);
    calculateAndUpdateScore(isAnsValid);
    setTimeout(() => {
      
      setShowWaitScreen(false);
      setLocalAnswer("");
      // setLocalScore(0);
      localScoreRef.current = 0;
      if (activeRound == NUMBER_OF_ROUNDS) {
        submitGame(isTrialGame);
      }
      else {
        setPauseTimer(false);
        setFilledLetters(
          Array.from({ length: ROUND_INFO_NEW[activeRound - 1].length }).fill({
            letter: 0,
            score: 0,
          })
        );
        setActiveRound((prev) => prev + 1);
      }
      setShowInGameNotificationPopup(false);
    }, 5000);
  }
  const renderBlanksSection = () => {
    return (


      <div className="flex w-[90vw] justify-center gap-3">
        {filledLetters.map((item, index) => {
          const { letter, score } = item;
          return <DragToBox value={letter} score={score} index={index} handleRemoveLetter={removeLetter} />;
        })}
      </div>
    );
  };

  const scoreVariants = {
    initial: { scale: 1, rotate: 0 },
    animate: {
      scale: [1, 2.5, 2.5, 2.5, 1],
      rotate: [0, 10, -10, 10, -10, 0],
      transition: { times: [0, 0.2, 0.4, 0.6, 0.8, 1] },
    },
  };



  const renderRoundInfoPopup = () => {
    return (
      <DarkModal isOpen={showRoundInfoPopup && !showWaitScreen}>
        <div className="flex items-center justify-center flex-col gap-6 w-full">
          <div className="text-2xl">Make A</div>
          <div className="text-primary-yellow relative flex w-full justify-center">
            <div className="text-8xl">
              {ROUND_INFO_NEW[activeRound - 1]?.length}
            </div>
            <div className="text-lg absolute right-0 bottom-[15px] w-20 text-white">
              Letter Word
            </div>
          </div>
        </div>
      </DarkModal>
    );
  };

  const getSuggestedWords = () => {
    const {foundWords} = getRandomLettersForGame(listOfWords.words, currentActiveQuestion.letters);
    switch (activeRound) {
      case 1:
        return foundWords.two.word
      case 2:
        return foundWords.three.word
      case 3:
        return foundWords.four.word
      case 4:
        return foundWords.five.word
      default:
        return foundWords.two.word
    }
  }
  
  return (
    <DndProvider backend={TouchBackend}>
      <div className="h-[100vh] w-[100vw] bg-primary-gray-20 space-y-5 flex flex-col justify-around">
        <div className="flex justify-center items-center gap-4">
          <div className="w-1/3 text-primary-yellow text-center">
            <div className="font-bold text-[14px]">Score:</div>
            <div className="font-bold text-[28px] text-white">
              {scoreRef.current}
            </div>
          </div>

          <div className="w-1/3">
            <Timer
              duration={ROUND_INFO_NEW[activeRound - 1]?.time}
              stroke={5}
              timerEnd={submitAnswer}
              startTimer={!pauseTimer}
              key={activeRound}
              localTimer={true}
              color={"#FFFFFF"}
              timeRef={timerRef}
            />
          </div>
          <div className="w-1/3 text-primary-yellow text-center">
            <div className="font-bold text-[14px]">Attempts:</div>
            <div className="font-bold text-[28px] text-white">
              {attemptsRef.current}/{totalQuestions * NUMBER_OF_ROUNDS}
            </div>
          </div>

        </div>

        {showWaitScreen ? (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.25 }}
              className="w-full h-full bg-primary-gray-20 flex items-start justify-center "
            >
              <div className="text-primary-yellow text-2xl font-bold flex flex-col gap-[15vh] mt-[15vh]">
                <div className="text-center text-white">
                  <span>Possible word : {getSuggestedWords()}</span>
                </div>

                <div className="grid grid-cols-2 w-full h-full gap-y-11 text-center">

                  <div className="flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div className="text-primary-yellow font-bold text-xl mb-2">
                        You Made
                      </div>
                      <div className="font-bold text-2xl text-white text-center">
                        {localSubmittedAnswer.answer || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div className="text-primary-yellow font-bold text-xl mb-2">
                        Score
                      </div>
                      <div className="font-bold text-2xl text-white text-center">
                        {localScoreRef.current}
                      </div>
                    </div>
                  </div>
                </div>
                {showInGameNotificationPopup && <InGameNotificationPopup showProceedAndTimeLeft={true} showTimer={currentActiveIndex != totalQuestions.length - 1 || activeRound != NUMBER_OF_ROUNDS} PopupDuration={5} />}
              </div>

            </motion.div>
          </AnimatePresence>
        ) : (
          <>
            <div className="text-primary-yellow text-center font-bold text-2xl h-full flex flex-col items-center justify-center">
              <div className="grid place-items-center gap-6 mx-2 mb-[20vh]">


                {renderBlanksSection()}
                <div className="text-primary-yellow font-bold gap-10 grid grid-cols-3 mt-[5vh]">
                  {/* Left column: empty */}
                  <div></div>

                  {/* Center column: Word */}
                  <div className="text-center">
                    <span>Word</span>
                    <div className="text-white text-xl">
                      {localAnswer}
                    </div>
                  </div>

                  {/* Right column: Animated presence */}
                  <div className="grid place-items-center">
                    {checkingAnswer ? <Loader /> : null}
                    <AnimatePresence>
                      {localAnswer ? (
                        showImage ? (
                          isAnsValid ? (
                            <motion.img
                              key="check-image"
                              alt="tick"
                              src="/Assets/Icons/tick-green-icon.svg"
                              className="w-7 h-7"
                              initial={{ scale: 0.3, opacity: 0.5 }}
                              animate={{ scale: 1, opacity: 1 }}
                              exit={{ scale: 0.3, opacity: 0 }}
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                              }}
                            />
                          ) : (
                            <motion.img
                              key="cross-image"
                              alt="cross"
                              src="/Assets/Icons/cross-icon.svg"
                              className="w-7 h-7"
                              initial={{ scale: 0.3, opacity: 0.5 }}
                              animate={{ scale: 1, opacity: 1 }}
                              exit={{ scale: 0.3, opacity: 0 }}
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                              }}
                            />
                          )
                        ) : null
                      ) : null}
                    </AnimatePresence>
                  </div>
                </div>

              </div>
              <div className="grid place-items-center gap-10">
                <div className="flex items-center justify-center gap-4 relative w-full">
                  <motion.div
                    animate={{
                      scale:
                        (filledLetters.some(({ letter }) => letter === 0)  ||
                          !localAnswer ||
                          !isAnsValid) ? [1, 1, 1] : [1, 1.1, 1],
                    }}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatDelay: 0.3,
                    }}
                  >
                    <AppButton
                      className="w-[140px]"
                      onClick={submitAnswer}
                      disabled={filledLetters.some(({ letter }) => letter === 0) || !isAnsValid}
                    >
                      Submit
                    </AppButton>
                  </motion.div>
                  <div className="absolute right-4">
                    <AppButton
                      className="w-[58px] h-9 bg-transparent border-2 border-solid border-primary-yellow text-primary-yellow font-bold rounded-lg text-xl p-0"
                      onClick={handleBackspaceClick}
                    >
                      ⌫
                    </AppButton>
                  </div>
                </div>
                {renderKeysSection()}
              </div>
            </div>
            {renderRoundInfoPopup()}
          </>
        )}
      </div>
      <MyPreview />
    </DndProvider>
  );
};

export default ScrabbleRoundGameBoard;
