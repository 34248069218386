import { useEffect, useState } from "react";
import { db } from './../../firebase-config';
import { collection, doc, getDoc, setDoc, onSnapshot, addDoc, query, orderBy, serverTimestamp } from "firebase/firestore";

const ChatRoom = ({ discordChannelId, setMessages, setMessage, userId, firstName, school, profileEmoji }) => {
  const [closeChat, setcloseChat] = useState(false); // ✅ Store closeChat state

  const isValidImageUrl = (url) => {
    return /\.(jpeg|jpg|png|gif|webp)(\?.*)?$/i.test(url) || 
           url.includes("firebasestorage.googleapis.com");
  };
  useEffect(() => {
    if (!discordChannelId) return;

    const checkOrCreateChatRoom = async () => {
      const chatRoomRef = doc(db, "tournamentChat", discordChannelId);
      const chatRoomSnap = await getDoc(chatRoomRef);

      if (!chatRoomSnap.exists()) {
        await setDoc(chatRoomRef, { createdAt: new Date(), closeChat: false });
      } 
    };

    checkOrCreateChatRoom();
  }, [discordChannelId]);

  useEffect(() => {
    if (!discordChannelId) return;

    const chatRoomRef = doc(db, "tournamentChat", discordChannelId);
    const unsubscribeChatStatus = onSnapshot(chatRoomRef, (docSnap) => {
      if (docSnap.exists()) {
        setcloseChat(docSnap.data().closeChat || false); // ✅ Fetch `closeChat` status
      }
    });

    const messagesRef = collection(db, "tournamentChat", discordChannelId, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribeMessages = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => {
      unsubscribeChatStatus();
      unsubscribeMessages();
    };
  }, [discordChannelId, setMessages]);

  const sendMessage = async (message) => {
    if (!discordChannelId || message.trim() === "" || closeChat) return; // ✅ Prevent sending if chat is closed

    try {
      const messageToSend = isValidImageUrl(message) ? message : message.trim();
      const messagesRef = collection(db, "tournamentChat", discordChannelId, "messages");

      await addDoc(messagesRef, {
        text: messageToSend,
        timestamp: serverTimestamp(),
        userId,
        firstName,
        school,
        profileEmoji: profileEmoji ?? null,
      });

      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return {
    sendMessage,
    closeChat, // ✅ Now returning closeChat so it can be passed to ChatRoomUI
  };
};

export default ChatRoom;
