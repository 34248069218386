import React, { useRef, useEffect, useState, useCallback } from "react";
import LinearTimerBar from "../LinearTimerBar";
import { NEW_FORMAT_TOURNAMENT_GAME_TIMER, NEW_FORMAT_TOURNAMENT_GAME_TRIAL_TIMER } from "../../../../../Constants/Commons";

const UnityGame = ({ user, tournamentId, activeRound, gameState, endgame, finishTrialAndStartGame, isTrial, handleNumberLineEnd, baseDifficulty }) => {
  const Timer = isTrial ? NEW_FORMAT_TOURNAMENT_GAME_TRIAL_TIMER["NUMBER_LINE"] : NEW_FORMAT_TOURNAMENT_GAME_TIMER["NUMBER_LINE"];
  const iframeRef = useRef(null);
  const [isUnityLoaded, setIsUnityLoaded] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(
    window.visualViewport ? window.visualViewport.height - 50 : window.innerHeight - 50
  );

  // Update the iframe height when the visual viewport resizes
  useEffect(() => {
    if (window.visualViewport) {
      const handleViewportResize = () => {
        setIframeHeight(window.visualViewport.height - 50);
      };

      window.visualViewport.addEventListener("resize", handleViewportResize);
      return () => {
        window.visualViewport.removeEventListener("resize", handleViewportResize);
      };
    }
  }, []);

  // ✅ Listen for Unity Ready Event
  useEffect(() => {
    const handleUnityReady = (event) => {
      if (event.data?.type === "UnityReady") {
        console.log("✅ Unity WebGL is ready!");
        setIsUnityLoaded(true);
      }
    };

    window.addEventListener("message", handleUnityReady);
    return () => window.removeEventListener("message", handleUnityReady);
  }, []);

  useEffect(() => {
    if (gameState?.startTime && !isTrial) {
      setStartTimer(true);
    }
    else if (gameState?.trialStartTime && isTrial) {
      setStartTimer(true);
    }
  }, [gameState, isTrial]);

  // ✅ Send Data to Unity
  const sendMessageToUnity = useCallback(() => {
    if (!user?.id) {
      console.error("❌ User data is missing. Cannot send message to Unity.");
      return;
    }

    if (iframeRef.current && isUnityLoaded) {
      const payload = {
        userId: user.id,
        tournamentId: tournamentId || "default_tournament",
        roundId: activeRound || "default_round",
        isTrial,
        baseDifficulty,
      };

      console.log("📤 Sending message to Unity:", payload);

      iframeRef.current.contentWindow.postMessage(
        { type: "ReactToUnity", payload: JSON.stringify(payload) },
        "*"
      );
    } else {
      console.warn("⏳ Unity is not ready yet. Retrying...");
    }
  }, [isUnityLoaded, user, tournamentId, activeRound, isTrial, baseDifficulty]);

  // ✅ Auto-send when Unity is ready
  useEffect(() => {
    if (isUnityLoaded) {
      sendMessageToUnity();
    }
  }, [isUnityLoaded, sendMessageToUnity]);

  return (
    <div
      className={`${gameEnded ? "hidden" : "flex flex-col justify-center items-center w-full overflow-hidden gap-4 mt-4"}`}
    >
      <LinearTimerBar
        totalDuration={Timer}
        startTimer={startTimer}
        isSelfTimer
        reset={startTimer}
        timerEnd={handleNumberLineEnd}
        // timeRef={timeRef}
      />

      <iframe
        ref={iframeRef}
        id="game_drop"
        src="https://html-classic.itch.zone/html/13205020/index.html"
        className="w-[95vw] border-0"
        style={{ height: `${iframeHeight}px` }}
        allowFullScreen
        allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; cross-origin-isolated; web-share"
        onLoad={() => console.log("🖼️ Unity iframe loaded.")}
      />
    </div>
  );

};

export default UnityGame;
