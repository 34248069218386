import React, { useRef, useEffect, useState } from "react";
import GIFBrowser from "./GIFBrowser";
import { Emoji } from "emoji-picker-react";

// Function to format markdown-like text (*bold*, _italic_, ~strikethrough~)
const formatMessageText = (text) => {
  if (!text) return "";

  return text
    .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Bold
    .replace(/_(.*?)_/g, "<em>$1</em>") // Italic
    .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
};

// Function to check if a URL is an image (supports Firebase Storage URLs)
const isValidImageUrl = (url) => {
  return (
    /\.(jpeg|jpg|png|gif|webp)(\?.*)?$/i.test(url) ||
    url.includes("firebasestorage.googleapis.com")
  );
};

const formatTimestamp = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return { date: "", time: "" };

  const messageDate = new Date(timestamp.seconds * 1000);
  const now = new Date();
  const isToday =
    now.getDate() === messageDate.getDate() &&
    now.getMonth() === messageDate.getMonth() &&
    now.getFullYear() === messageDate.getFullYear();

  const date = isToday
    ? "Today at"
    : `${messageDate.getDate()}/${
        messageDate.getMonth() + 1
      }/${messageDate.getFullYear()}`;

  const time = `${messageDate.getHours()}:${messageDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  return { date, time };
};

const ChatRoomUI = ({ messages, message, setMessage, sendMessage, height ,closeChat }) => {
  const [showGifBrowser, setShowGifBrowser] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const messageContainer = document.getElementById("messagesContainer");
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  }, [messages]);

  const handleGifSelection = (gifUrl) => {
    setMessage(gifUrl);
    setShowGifBrowser(false);
  };

  return (
    <div style={{ ...styles.container, height: `${height}px` }}>
      <div id="messagesContainer"  style={{ ...styles.messagesContainer, height: `${height - 100}px` }}>
        {messages.map((msg) => {
          const isAdmin = msg.school === "Maidaan";
          const { date, time } = formatTimestamp(msg.timestamp);

          return (
            <div
              key={msg.id}
              style={isAdmin ? styles.adminMessage : styles.userMessage}
            >
              <div style={styles.profileContainer}>
                {!msg.profileEmoji ? (
                  <img
                    src={
                      isAdmin
                        ? "Assets/Icons/maidaan-logo-dark.svg"
                        : "Assets/Images/Nimbu.svg"
                    }
                    alt="Profile"
                    style={styles.profileImage}
                  />
                ) : (
                  <Emoji unified={msg.profileEmoji} size="25" />
                )}
              </div>

              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "5px", fontSize: "12px" }}>
                  <span style={isAdmin ? styles.MaidaanText : styles.Nametext}>
                    {isAdmin ? msg.school : msg.firstName} {isAdmin ? "" : ","}
                  </span>
                  <span style={styles.schoolText}>
                    {" "}
                    {isAdmin ? "" : msg.school}
                  </span>
                  <span style={styles.timestamp}>{`${date} ${time}`}</span>
                </div>

                {isValidImageUrl(msg.text) ? (
                  <img
                    src={msg.text}
                    alt="Uploaded"
                    style={styles.messageImage}
                  />
                ) : (
                  <p
                    style={styles.messageText}
                    dangerouslySetInnerHTML={{
                      __html: formatMessageText(msg.text),
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {showGifBrowser && <GIFBrowser onSelectGif={handleGifSelection} />}
      {closeChat ? (
        <p
          style={{
            textAlign: "center",
            color: "#CCF900",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Chat disabled by admin.
        </p>
      ) : (
        <div style={styles.inputContainer}>
          <button
            onClick={() => setShowGifBrowser(!showGifBrowser)}
            style={styles.gifButton}
          >
            {showGifBrowser ? "GIFs" : "GIF"}
          </button>

          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Start Typing..."
            style={styles.input}
          />

          <button
            onClick={() => sendMessage(message)}
            style={styles.sendButton}
          >
            <img
              src="Assets/Images/sendImage.svg"
              alt="Send"
              style={styles.sendIcon}
            />
          </button>
        </div>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#191919",
    border: "1px solid #CCF900",
    borderRadius: "12px",
    padding: "15px",
    maxWidth: "100%",
    height: "100vh",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  messagesContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    padding: "10px",
    overflowY: "scroll",
    backgroundColor: "#222",
    flexGrow: 1,
  },
  adminMessage: {
    display: "flex",
    padding: "12px",
    margin: "9px 0",
    color: "#fff",
    borderRadius: "10px",
    position: "relative",
  },
  userMessage: {
    display: "flex",
    padding: "12px",
    margin: "9px 0",
    color: "#fff",
    borderRadius: "10px",
    position: "relative",
  },
  profileContainer: {
    display: "flex",
    alignItems: "top",
    marginRight: "10px",
  },
  profileImage: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  messageImage: {
    maxWidth: "100%", // Ensures the image scales properly
    maxHeight: "200px", // Limits height to prevent overly large images
    borderRadius: "10px",
    objectFit: "cover", // Ensures images fit neatly within their box
  },
  schoolText: {
    color: "#CCF900",
    fontSize: "9px",
  },
  messageText: {
    margin: 0,
    fontSize: "14px",
    lineHeight: "1.5",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  timestamp: {
    fontSize: "8px",
    color: "#d3d9e1",
    textAlign: "right",
    position: "absolute",
    top: "18px",
    marginLeft: "5px",
  },
  Nametext: {
    fontSize: "15px",
  },
  MaidaanText: {
    fontSize: "15px",
    color: "#CCF900",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#191919",
    borderRadius: "8px",
    border: "1px solid #CCF900",
    marginTop: "10px",
    width: "100%",
  },
  input: {
    flex: 1, // Takes up all available space
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    fontSize: "14px",
    outline: "none",
    backgroundColor: "#222",
    color: "#fff",
  },
  gifButton: {
    padding: "5px 12px",
    borderRadius: "8px",
    backgroundColor: "#CCF900",
    color: "#191919",
    fontWeight: "bold",
    cursor: "pointer",
    border: "none",
    fontSize: "12px",
    marginRight: "8px",
    flexShrink: 0, // Prevents shrinking
  },
  sendButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    marginLeft: "8px",
    flexShrink: 0, // Prevents shrinking
  },
  sendIcon: {
    width: "30px",
    height: "30px",
  },
};

export default ChatRoomUI;
