
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../../providers/auth-provider";
import AppButton from "../../Common/AppButton";
import "../../../../src/index.css";
import mixpanel from 'mixpanel-browser';
import { useNavigate, useSearchParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { secretKey, initializationVector } from "../../../firebase-config";
import { Emoji } from "emoji-picker-react";
import Loader from "../Loader";

const ReactPollComponent = ({ pollQuestion, setPollCount, questions, unansweredQuestions, setQuestions, setUnansweredQuestions, removeFromUnAnswered, internalLoading, followedUsers, setFollowedUsers }) => {
  const { user } = useAuth();


  const [pollAns, setPollAns] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { createdBy, userId } = pollQuestion || {};
  const isAdmin = !userId || userId === "admin"
  const followingCreator = pollQuestion?.followingCreator || followedUsers.includes(pollQuestion.userId) || pollQuestion.userId === user.id;
  const profileEmoji = createdBy?.profileEmoji;
  const navigate = useNavigate();
  useEffect(() => {
    setPollAns([...pollQuestion.pollAnswers]);
    setIsAnswered(pollQuestion.isAnswered || false);
    setSelectedOption(pollQuestion.isAnswered ? pollQuestion.selectedOption : null);
  }, [pollQuestion]);

  const handleNavigateProfilePage = (userId, navigate) => {
    if (!userId) return; // Prevent navigation if userId is missing

    // Encrypt the user ID and navigate to the profile page
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const iv = CryptoJS.enc.Hex.parse(initializationVector);

    const encryptedUserId = CryptoJS.AES.encrypt(userId, key, { iv: iv }).toString();
    let urlSafeEncryptedUserId = encryptedUserId.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

    navigate(`/profile/${urlSafeEncryptedUserId}`);
  };
  const [isFlipped, setIsFlipped] = useState(false);
  const containerHeight = "400px";

  const totalVotes = pollAns.reduce((sum, answer) => sum + answer.votes, 0);


  const handleFollow = async (creatorId, userId) => {
    //remove follow button.
    setFollowedUsers((prev) => [...prev, creatorId]);

    const response = await axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/follow-creator`, {
      creatorId: creatorId,
      userId: userId,
    });

  };

  const handleOptionChange = (option) => {
    if (!isAnswered) {
      setSelectedOption(option);
    }
  };

  const handleSubmit = () => {
    mixpanel.identify(user.id);
    mixpanel.track('PollCard_action', {
      action: "Submit",
      pollId: pollQuestion.id,
    });
    const updatedPollAnswers = pollAns.map((answer) => {
      if (answer.option === selectedOption) {
        return { ...answer, votes: answer.votes + 1 };
      }
      return answer;
    });
    setPollAns(updatedPollAnswers);
    setIsAnswered(true);

    const updatedQuestions = questions.map((question) => {
      if (question.id === pollQuestion.id) {
        return {
          ...question,
          isAnswered: true,
          selectedOption: [selectedOption],
          pollAnswers: updatedPollAnswers,
        };
      }
      return question;
    });
    setQuestions(updatedQuestions);

    //if answered and unanswered questions are not same then remove the answered question from unanswered questions
    if (removeFromUnAnswered) {
      const updatedUnansweredQuestions = unansweredQuestions.filter((question) => question.id !== pollQuestion.id);
      setUnansweredQuestions(updatedUnansweredQuestions);
    }


    setPollCount((prev) => ({
      pollVotesCount: prev.pollVotesCount + 1,
      pollQuestionsCount: prev.pollQuestionsCount,
    }));

    axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/update-poll-resoponse`, {
      pollId: pollQuestion.id,
      selectedOption: [selectedOption],
      userId: user.id,
      createdAt: new Date(),
    });
  };

  const handleMore = () => {
    mixpanel.identify(user.id);
    mixpanel.track('PollCard_action', {
      action: "More",
      pollId: pollQuestion.id,
    });
    setIsFlipped(true);
  };

  const handleBack = () => {
    mixpanel.identify(user.id);
    mixpanel.track('PollCard_action', {
      action: "Back",
      pollId: pollQuestion.id,
    });
    setIsFlipped(false);
  };


  const getOptionStyle = (option) => {
    const isSelected = option === selectedOption?.[0] || selectedOption === option;
    return {
      border: `2px solid ${isSelected ? "#ccf900" : "gray"}`,
      backgroundColor: isSelected ? "rgba(204, 249, 0, 0.4)" : "#fff",
      borderRadius: "5px",
      padding: "10px 20px",
      margin: "10px auto",
      width: "100%",
      textAlign: "center",
      color: isSelected ? "white" : "black",
      cursor: !isAnswered ? "pointer" : "default",
      fontSize: "14px",
    };
  };

  return (
    <div style={{ perspective: "1000px", width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      {!internalLoading && <div
        style={{
          position: "relative",
          width: "90%",
          margin: "0 auto",
          height: "100%",
          transformStyle: "preserve-3d",
          transition: "transform 0.6s",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {/* Front Side */}

        <div style={{
          position: "absolute", width: "100%", height: "100%",
          backfaceVisibility: "hidden", // Hide front when flipped

        }}>
          {/* Main content area: occupies available space minus button height */}
          <div
            style={{
              position: "relative", // needed for absolute positioning of admin header
              height: "calc(100% - 30px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              boxSizing: "border-box",
              textAlign: "center",
              width: "100%",
              gap: "8px",
            }}
          >
            <div
              className={`absolute top-1 left-0 w-full border-b border-gray-600 text-start text-sm items-start justify-center  flex flex-col gap-2
                ${!isAdmin ? "cursor-pointer" : "cursor-default"}`}
            >
              <div className="flex items-center gap-1"
                onClick={!isAdmin ? () => handleNavigateProfilePage(userId, navigate) : undefined}
              >
                <span>By:</span>
                <div className="flex items-center">
                  {isAdmin ? (
                    <div className="flex items-center">
                      <img
                        src="/Assets/Icons/maidaan-logo-dark.svg"
                        alt="icon"
                        className="w-6 h-6 rounded-full"
                      />
                    </div>
                  ) : (
                    profileEmoji ? (
                      <div className="flex items-center rounded-full">
                        <Emoji unified={profileEmoji} size="24" />
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <img
                          src="/Assets/Icons/Nimbu.svg"
                          alt="icon"
                          className="w-6 h-6 rounded-full"
                        />
                      </div>
                    )
                  )}


                </div>

                <span>{isAdmin ? "Maidaan" : createdBy?.firstName || "Maidaan"}</span>
                {createdBy?.school != null ?
                  <span>,{isAdmin ? "" : createdBy?.school || ""}</span>
                  : <span></span>}
              </div>

              {!followingCreator && !isAdmin && (
                <div
                  className="bg-[#3a3a3a] text-[#ccf900] text-center rounded-full px-2 pt-0.75  pb-0.5 text-xs cursor-pointer border border-solid border-primary-yellow h-[20px] flex items-center justify-center"
                  onClick={() => { handleFollow(pollQuestion.userId, user.id) }}
                >
                  Follow
                </div>
              )}
            </div>



            {/**topic */}
            <div
              className={`absolute top-1 right-0 w-[100px] border-b border-gray-600 text-end text-[12px] flex items-center justify-end mt-[2px] pt-[2px]`}
            >
              {pollQuestion.topic}
            </div>





            {/* <div 
              style={{
                fontFamily: '"Avenir Heavy", sans-serif',
                fontSize: "16px",
                textAlign: "start",
                margin: 0,
                marginBottom: "16px",
                width: "100%",
              }}
              >
                {pollQuestion.headline}
              </div> */}
            <div className="font-light text-base text-left m-0 w-full">
              {pollQuestion.question}
            </div>

            <div style={{ width: "100%" }}>
              {pollAns.map((answer, index) => (
                <div
                  key={index}
                  style={getOptionStyle(answer.option)}
                  onClick={() => !isAnswered && handleOptionChange(answer.option)}
                  className="h-[30px] flex items-center justify-center"
                >
                  {isAnswered ? (
                    <span className="text-[12px]">
                      {answer.option} -{" "}
                      {totalVotes > 0 ? ((answer.votes / totalVotes) * 100).toFixed(1) : 0}%
                    </span>
                  ) : (
                    <span className="text-[14px]">{answer.option}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Button container fixed at the bottom */}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: 0,
              right: 0,
              textAlign: "center",
            }}
          >
            {!isAnswered ? (
              <AppButton
                onClick={handleSubmit}
                disabled={selectedOption === null || isAnswered}
                style={{
                  width: "80px",
                  backgroundColor: "#ccf900",
                  color: "black",
                  fontSize: "12px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                Submit
              </AppButton>
            ) : (
              pollQuestion.trivia && <>
                <AppButton
                  onClick={handleMore}
                  disabled={false}
                  style={{
                    width: "80px",
                    backgroundColor: "#ccf900",
                    color: "black",
                    fontSize: "12px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  More
                </AppButton>
              </>
            )}
          </div>
        </div>

        {/* Back Side */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {/* Back side content area */}
            <div
              style={{
                height: "calc(100% - 60px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                boxSizing: "border-box",
                textAlign: "center",
                width: "100%",
              }}
            >
              {/* <div className="font-avenir font-heavy text-base text-left w-full mb-4"> */}
              {/* <div
                style={{
                  fontFamily: '"Avenir Heavy", sans-serif',
                  fontSize: "16px",
                  textAlign: "start",
                  margin: 0,
                  marginBottom: "16px",
                  width: "100%",
                }}
              >
                {pollQuestion.headline}
              </div> */}
              {!!pollQuestion.image && (
                <img
                  src={pollQuestion.image}
                  alt="poll"
                  style={{ height: "160px", width: "auto", objectFit: "cover", marginBottom: "16px", borderRadius: "5px" }}
                />
              )}
              <span className="text-base font-avenir font-light text-left">

                {pollQuestion.trivia}
              </span>
            </div>
            {/* Back button fixed at the bottom */}
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <AppButton
                onClick={handleBack}
                disabled={false}
                style={{
                  width: "80px",
                  backgroundColor: "#ccf900",
                  color: "black",
                  fontSize: "12px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                Back
              </AppButton>
            </div>
          </div>
        </div>
      </div>}
      {internalLoading && <div className="absolute top-0 left-0 w-full h-full bg-opacity-90 flex items-center justify-center z-10">
        <Loader />
      </div>}
    </div>
  );
};

export default ReactPollComponent;

