import { twMerge } from "tailwind-merge";

const PricePill = ({
  data,
  selectedPlan,
  setSelectedPlan,
  showTournaments = false,
  abbreviatedName,
  selectedPlanExpired = false,
}) => {
  const { id, amount, name, discountedAmount, tournamentsPerPlan } = data;
  const handlePriceSelect = () => {
    setSelectedPlan(data);
  };

  return (
    <div
      className={twMerge(
        "rounded-lg py-1 md:py-2 border border-solid border-[#FFFFFF4D] bg-[#FFFFFF33] px-5 pl-3 pr-2 flex items-center",
        selectedPlan.id === id && "bg-[#FFFFFF66] border-0"
      )}
      style={{
        outline: selectedPlan.id === id ? "3.5px solid #CCF900CC" : "",
      }}
      onClick={handlePriceSelect}
    >
      {!selectedPlanExpired ? (
        <div className="relative w-full flex items-center">
          {/* Left Section */}
          <div className="flex-1 flex justify-end mr-2">
            <span className="text-[10px] md:text-sm">
              {showTournaments || discountedAmount === amount ? (
                `${tournamentsPerPlan} Tournaments`
              ) : abbreviatedName ? (
                <span style={{ fontSize: "16px" }}>
                  {abbreviatedName} Offer
                </span>
              ) : null}
            </span>
          </div>
          {/* Vertical Separator fixed at the center */}
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <span className="text-[#FFFFFF4d] md:text-xl">|</span>
          </div>
          {/* Right Section */}
          <div className="flex-1 flex justify-start ml-2">
            <span className="text-[14px] md:text-xl">
              ₹{discountedAmount}
              {amount !== discountedAmount && (
                <span className="text-[10px] md:text-sm line-through opacity-50 ml-[2px] md:ml-[6px]">
                  ₹{amount}
                </span>
              )}
            </span>
          </div>
        </div>
      ) : (
        // If the plan is expired, center just the price.
        <div className="w-full flex justify-center">
          <span className="text-[14px] md:text-xl">
            ₹{discountedAmount}
            {amount !== discountedAmount && (
              <span className="text-[10px] md:text-sm line-through opacity-50 ml-[2px] md:ml-[6px]">
                ₹{amount}
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default PricePill;
