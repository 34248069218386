import React, { useState, useEffect, useRef } from "react";
import { db } from "../../../firebase-config";
import {
    collection,
    doc,
    getDoc,
    addDoc,
    serverTimestamp,
} from "firebase/firestore";
import axios from "axios";
import { ReactComponent as CircularPlus } from "../../../assets/icons/circular-plus.svg";
import { ReactComponent as CircularMinus } from "../../../assets/icons/circular-minus.svg";
import AppButton from "../../Common/AppButton";

const PollCreator = ({ onClose, userId, setShowPollSubmittedPopup }) => {
    const [question, setQuestion] = useState("");
    // Default options now contains three empty strings
    const [options, setOptions] = useState(["", "", ""]);
    // Error state for headline, question, and options (as an array) now has three entries for options
    const [errors, setErrors] = useState({
        question: "",
        options: ["", "", ""],
    });

    const firstInputRef = useRef(null);

    // Reset state when modal opens
    useEffect(() => {
        setQuestion("");
        setOptions(["", "", ""]);
        setErrors({ question: "", options: ["", "", ""] });
        setTimeout(() => firstInputRef.current?.focus(), 100); // Auto-focus input
    }, []);

    const addOption = () => {
        if (options.length >= 5) return alert("Maximum 5 options allowed");
        setOptions([...options, ""]);
        setErrors((prev) => ({
            ...prev,
            options: [...prev.options, ""],
        }));
    };

    const removeOption = (index) => {
        // Only allow removal of options after the first two
        if (index < 2) return;
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
        setErrors((prev) => ({
            ...prev,
            options: prev.options.filter((_, i) => i !== index),
        }));
    };

    const handleOptionChange = (index, value) => {
        setOptions((prevOptions) =>
            prevOptions.map((opt, i) => (i === index ? value : opt))
        );
        // Clear error for this option if value is non-empty
        if (value.trim() !== "") {
            setErrors((prev) => {
                const newOptionsErrors = [...prev.options];
                newOptionsErrors[index] = "";
                return { ...prev, options: newOptionsErrors };
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasError = false;
        const newErrors = {
            question: "",
            options: options.map(() => ""),
        };

        if (!question.trim()) {
            newErrors.question = "This field is required";
            hasError = true;
        }
        newErrors.options = options.map((opt) => {
            if (!opt.trim()) {
                hasError = true;
                return "This field is required";
            }
            return "";
        });

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/add-poll`, {
            question,
            options,
            userId,
        });

        setShowPollSubmittedPopup(true);
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
          <div className="bg-black p-5 rounded-lg w-[90%] max-w-[400px] shadow-lg text-white">
            <h2 className="text-center text-xl mb-2 text-[#ccf900]">
              Submit Your Poll
            </h2>
            <p className="text-center text-sm mb-4 text-gray-400 font-medium">
              Want to ask a question to your friends? Submit your poll and if approved
              get featured
            </p>
    
            {/* Wrap the form in a left-aligned container */}
            <div className="text-left">
              <form onSubmit={handleSubmit}>
                <label className="block text-sm font-bold mt-2">
                  Enter <span className="italic">Question</span>
                </label>
                <input
                  type="text"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrors((prev) => ({ ...prev, question: "" }));
                    }
                  }}
                  className="w-[90%] p-2 mt-1 border border-gray-300 rounded text-sm text-black"
                />
                {errors.question && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.question}
                  </p>
                )}
    
                <label className="block text-sm font-bold mt-2">
                  Enter <span className="italic">Options</span>
                </label>
                {options.map((option, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={option}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        placeholder={`Option ${index + 1}`}
                        className="w-[90%] p-2 mt-1 border border-gray-300 rounded text-sm text-black"
                      />
                      {index >= 2 && (
                        <div
                          onClick={() => removeOption(index)}
                          className="ml-2 mt-1 bg-black cursor-pointer"
                        >
                          <CircularMinus className="w-6 h-6" />
                        </div>
                      )}
                    </div>
                    {errors.options && errors.options[index] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.options[index]}
                      </p>
                    )}
                  </div>
                ))}
    
                {options.length < 5 && (
                  <div
                    className="flex items-center mt-2 cursor-pointer"
                    onClick={addOption}
                  >
                    <CircularPlus className="w-6 h-6" />
                    <span className="ml-2 text-[#ccf900] underline">Add Option</span>
                  </div>
                )}
    
                <div className="flex flex-col items-center justify-center mt-5 gap-4">
                  <AppButton
                    onClick={handleSubmit}
                    className="bg-[#ccf900] text-black px-4 py-2 rounded-xl min-w-[150px]"
                  >
                    Submit
                  </AppButton>
                  <div
                    className="w-full h-0.5 underline text-[#ccf900] cursor-pointer flex-col text-center"
                    onClick={onClose}
                  >
                    Cancel
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    };
    
    export default PollCreator;