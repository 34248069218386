import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPollComponnet from "./PollUI";
import ArenaHeader from "../../../GamesArena/Common/ArenaHeader";
import axios from "axios";
import { useAuth } from "../../../providers/auth-provider";
import { filter, findIndex, set } from "lodash";
import { render } from "@testing-library/react";
import PollCreator from "./PollCreaction";
import ProgressBar from "./ProgressBar";
import { Dialog } from "@mui/material";
import Lottie from "lottie-react";
import confettiAnimation from "../../../assets/animations/confetti.json";
import AppButton from "../../Common/AppButton";
import mixpanel from 'mixpanel-browser';
import { useNavigate, useSearchParams } from "react-router-dom";
// import Loader from './PageComponents/Loader';
import Loader from "../../PageComponents/Loader";

const GKPolls = () => {


    const topicsArray = ["My Choice", "Thoughts", "Riddles", "Sports", "Technology", "Headlines", "Music", "Science", "History", "Math", "Geography", "Literature"]
    const { user } = useAuth();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [unansweredQuestions, setUnansweredQuestions] = useState([]);
    const [topicQuestions, setTopicQuestions] = useState({});
    // Track the last document's ID for pagination
    const [lastDocId, setLastDocId] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [primaryFilterOption, setPrimaryFilterOption] = useState("all");
    const [selectedTopic, setSelectedTopic] = useState("");
    const [topicHasMore, setTopicHasMore] = useState(true);
    const [lastTopicDocId, setLastTopicDocId] = useState({});
    const [showCreationPopup, setShowCreationPopup] = useState(false);
    const [pollCount, setPollCount] = useState(0);
    const [showPollSubmittedPopup, setShowPollSubmittedPopup] = useState(false);
    const [userDetailsMap, setUserQuestions] = useState([]);
    const [madeByYouMap, setmadeByYouMap] = useState([]);
    const [followedUsers, setFollowedUsers] = useState([]);
    const [madeByYouHasMore, setmadeByYouHasMore] = useState(true);
    const [madeByYouLastDocId, setMadeByYouLastDocId] = useState(null);
    const [userPollsHasMore, setUserPollsHasMore] = useState(true);
    const [userPollsLastDocId, setUserPollsLastDocId] = useState(null);
    const [answeredUnansweredHasMore, setAnsweredUnansweredHasMore] = useState({ all: true, unanswered: true, answered: true, following: true, madeByYou: true });
    const [answeredUnansweredLastDocId, setAnsweredUnansweredLastDocId] = useState({ all: null, unanswered: null, answered: null, following: null, madeByYou: null });
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [followingUsersQuestions, setFollowingUsersQuestions] = useState([]);
    const [swiperRendered, setSwiperRendered] = useState(false);
    const [internalLoading, setInternalLoading] = useState(false);
    const [pollsSubmittedByUser, setPollsSubmittedByUser] = useState(-1);
    const [numberOfFollowers, setNumberOfFollowers] = useState(-1);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => setWindowHeight(window.innerHeight);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const swiperHeight = windowHeight * 0.75;

    const [searchParams, setSearchParams] = useSearchParams();
    const isUserPolls = searchParams.get("u") === "Y";
    const getStyle = (filterIndex, primaryFilterOption) => {
        if (selectedFilter === filterIndex && primaryFilterOption !== "all") {
            return {
                backgroundColor: "#ccf900",
                color: "black",
                border: "2px solid #3a3a3a",
            };
        } else {
            return {
                backgroundColor: "#4e4e4e",
                color: "#ccf900",
                border: "2px solid #ccf900",
            };
        }
    };

    const fetchLimit = 30;
    const fetchTopicPolls = async (filter, topic, dataListExits = false) => {
        if (loading || !setTopicHasMore) return;
        // setLoading(true);
        if (!dataListExits) {
            setLoading(true);
        }
        else {
            setInternalLoading(true);
        }

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/get-polls`,
                {
                    queryFilter: "topic",
                    userId: user.id,
                    fetchLimit,
                    lastDocId: lastTopicDocId[topic],
                    topic: topic
                }
            );

            const newTopicPolls = data.data.data
            const topicwiseQuestions = topicQuestions[topic] || [];
            const combined = [...topicwiseQuestions, ...newTopicPolls];
            const uniqueTopicPolls = combined.filter((item, index, self) =>
              index === self.findIndex(obj => obj.id === item.id)
            );           
            setTopicQuestions({ ...topicQuestions, [topic]: uniqueTopicPolls });

            if (newTopicPolls.length > 0) {
                setLastTopicDocId({ [topic]: newTopicPolls[newTopicPolls.length - 1].id });
            }

            if (newTopicPolls.length < fetchLimit) {
                setTopicHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
        if (!dataListExits) {
            setLoading(false);
        }
        else {
            setInternalLoading(false);
        }

    };

    const fetchPolls = async (dataListExits = false) => {
        if (loading || !hasMore) return;
        // setLoading(true);
        if (!dataListExits) {
            setLoading(true);
        }
        else {
            setInternalLoading(true);
        }
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/get-polls`,
                {
                    queryFilter: "all",
                    userId: user.id,
                    fetchLimit,
                    lastDocId,
                }
            );


            let newPolls = data.data.data;

            if (newPolls.length > 0) {
                setLastDocId(newPolls[newPolls.length - 1].id);
            }
            // Sort the polls based on the given criteria
            newPolls = newPolls.sort((a, b) => {
                const isAUnanswered = !a.isAnswered;
                const isBUnanswered = !b.isAnswered;

                const isAAdmin = a.userId === "admin";
                const isBAdmin = b.userId === "admin";

                if (isAUnanswered && !isAAdmin) return -1; // Unanswered (non-admin) first
                if (isBUnanswered && !isBAdmin) return 1;

                if (isAUnanswered && isAAdmin) return -1; // Unanswered (admin) second
                if (isBUnanswered && isBAdmin) return 1;

                return 1; // Answered last
            });

            // Apply fetch limit after sorting
            newPolls = newPolls.slice(0, fetchLimit);

            const validPolls = newPolls.map((poll) => ({
                ...poll,
                createdBy: poll.createdBy
            }));

            if (selectedFilter === 1) {
                setQuestions(validPolls.filter(poll => poll.userId === user.id));
            } else {
                setQuestions((prev) => [...prev, ...validPolls]);
            }

            // Update lastDocId for pagination


            if (newPolls.length < fetchLimit) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
        if (!dataListExits) {
            setLoading(false);
        }
        else {
            setInternalLoading(false);
        }
    };

    const fetchAnsweredUnansweredPolls = async (dataListExits = false) => {
        if (loading || !answeredUnansweredHasMore[primaryFilterOption]) return;
        if (!dataListExits) {
            setLoading(true);
        }
        else {
            setInternalLoading(true);
        }

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/get-answered-unanswered-polls`,
                {
                    userId: user.id,
                    fetchLimit,
                    lastDocId: answeredUnansweredLastDocId[primaryFilterOption],
                    queryFilter: primaryFilterOption
                }
            );

            let newPolls = data.data.data;
            const lastDocId = newPolls[newPolls.length - 1].id;

            newPolls.sort((a, b) => {
                if (a.isAnswered === b.isAnswered) return 0;
                return a.isAnswered ? 1 : -1;
            });

            if (primaryFilterOption === "unanswered") {
                setUnansweredQuestions((prev) => [...prev, ...newPolls]);
            } else if (primaryFilterOption === "answered") {
                setAnsweredQuestions((prev) => [...prev, ...newPolls]);
            }
            else if (primaryFilterOption === "all") {
                setAllQuestions((prev) => [...prev, ...newPolls]);
            }
            else if (primaryFilterOption === "following") {
                setFollowingUsersQuestions((prev) => [...prev, ...newPolls]);
            }

            // Update lastDocId for pagination
            if (newPolls.length > 0) {
                setAnsweredUnansweredLastDocId({ ...answeredUnansweredLastDocId, [primaryFilterOption]: lastDocId });
            }

            if (newPolls.length < fetchLimit) {
                setAnsweredUnansweredHasMore({ ...answeredUnansweredHasMore, [primaryFilterOption]: false });
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }

        if (!dataListExits) {
            setLoading(false);
        }
        else {
            setInternalLoading(false);
        }
    };





    const fetchUserPolls = async () => {
        if (loading || !userPollsHasMore) return;
        setLoading(true);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/get-polls`,
                {
                    queryFilter: "ID",
                    userId: user.id,
                    fetchLimit,
                    lastDocId: userPollsLastDocId
                }
            );

            let newPolls = data.data.data;


            const lastDocId = newPolls[newPolls.length - 1].id;

            newPolls.sort((a, b) => {
                if (a.isAnswered === b.isAnswered) return 0;
                return a.isAnswered ? 1 : -1;
            });

            const validPolls = newPolls.map((poll) => ({
                ...poll,
                createdBy: poll.createdBy
            }));

            //now sort the poll in the order of !isAnswered  first anf isAnswered last

            if (selectedFilter === 1) {
                setUserQuestions(validPolls.filter(poll => poll.userId === user.id));
            } else {
                setUserQuestions((prev) => [...prev, ...validPolls]);
            }

            // Update lastDocId for pagination
            if (newPolls.length > 0) {
                setUserPollsLastDocId(lastDocId);
            }

            if (newPolls.length < fetchLimit) {
                setUserPollsHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
        setLoading(false);
    };


    const fetchMadeByYou = async (dataListExits = false) => {
        if (loading || !madeByYouHasMore) return;
        if (!dataListExits) {
            setLoading(true);
        }
        else {
            setInternalLoading(true);
        }
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/get-polls`,
                {
                    queryFilter: "MadeByYou",
                    userId: user.id,
                    fetchLimit,
                    lastDocId: madeByYouLastDocId
                }
            );

            let newPolls = data.data.data;

            const lastDocId = newPolls[newPolls.length - 1].id;
            // Apply fetch limit
            // newPolls = newPolls.slice(0, fetchLimit);

            newPolls.sort((a, b) => {
                if (a.isAnswered === b.isAnswered) return 0;
                return a.isAnswered ? 1 : -1;  // false comes before true
            });

            const validPolls = newPolls.map((poll) => ({
                ...poll,
                createdBy: poll.createdBy
            }));

            const uniqueMadeByYouPolls = [...new Set([...madeByYouMap, ...validPolls])];
            setmadeByYouMap(uniqueMadeByYouPolls);
            // }

            // Update lastDocId for pagination
            if (newPolls.length > 0) {
                setMadeByYouLastDocId(lastDocId);
            }

            // If fewer than fetchLimit polls are returned, mark that there are no more polls
            if (newPolls.length < fetchLimit) {
                setmadeByYouHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
        if (!dataListExits) {
            setLoading(false);
        }
        else {
            setInternalLoading(false);
        }
    };


    const fetchPollCount = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/poll-count`, {
                userId: user.id
            }
            );
            setPollCount(data.data);
        }
        catch (error) {
            console.error("Error fetching poll count:", error);
        }
    };

    const fetchNumberOfFollowers = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/no-of-followers`, {
                userId: user.id
            }
            );
            setNumberOfFollowers(data.data);
        }
        catch (error) {
            console.error("Error fetching number of followers:", error);
        }
    };

    const fetchPollsSubmittedByUser = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_NODE_BASE_URL}/gkpolls/polls-submitted-by-user`, {
                userId: user.id
            });
            setPollsSubmittedByUser(data.data);
        }
        catch (error) {
            console.error("Error fetching polls submitted by user:", error);
        }
    };

    useEffect(() => {
        // if (selectedFilter === 0 && madeByYouMap.length < fetchLimit && madeByYouHasMore) {
        //     fetchMadeByYou();
        // }
        if (selectedFilter === 0) {
            if (primaryFilterOption === "unanswered" && unansweredQuestions.length < fetchLimit && answeredUnansweredHasMore["unanswered"]) {
                fetchAnsweredUnansweredPolls();
            }
            else if (primaryFilterOption === "answered" && answeredQuestions.length < fetchLimit && answeredUnansweredHasMore["answered"]) {
                fetchAnsweredUnansweredPolls();
            }
            else if (primaryFilterOption === "all" && allQuestions.length < fetchLimit && answeredUnansweredHasMore["all"]) {
                fetchAnsweredUnansweredPolls();
            }
            else if (primaryFilterOption === "following" && followingUsersQuestions.length < fetchLimit && answeredUnansweredHasMore["following"]) {
                fetchAnsweredUnansweredPolls();
            }
            else if (primaryFilterOption === "madeByYou" && madeByYouMap.length < fetchLimit && madeByYouHasMore) {
                fetchMadeByYou();
            }
        }
        else if (selectedFilter === 2 && selectedTopic && (!topicQuestions?.[selectedTopic]?.length || topicQuestions?.[selectedTopic]?.length < fetchLimit)) {
            fetchTopicPolls(selectedFilter, selectedTopic);
        }
        if (selectedFilter === 4 && userDetailsMap.length < fetchLimit && userPollsHasMore) {
            if (pollsSubmittedByUser === -1) {
                fetchPollsSubmittedByUser();
            }
            if (numberOfFollowers === -1) {
                fetchNumberOfFollowers();
            }
            fetchUserPolls();
        }
    }, [selectedFilter, selectedTopic, primaryFilterOption]);

    // When the slide changes, if near the end, load more polls
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
        // if (hasMore && !loading && (swiper.activeIndex >= questions.length - 2 && selectedFilter === 0)) {
        //     fetchPolls();
        // }
        if (primaryFilterOption === "unanswered" && answeredUnansweredHasMore["unanswered"] && !loading && (swiper.activeIndex >= unansweredQuestions.length - 2 && selectedFilter === 0)) {
            fetchAnsweredUnansweredPolls(true);
        }
        else if (primaryFilterOption === "answered" && answeredUnansweredHasMore["answered"] && !loading && (swiper.activeIndex >= answeredQuestions.length - 2 && selectedFilter === 0)) {
            fetchAnsweredUnansweredPolls(true);
        }
        else if (primaryFilterOption === "all" && answeredUnansweredHasMore["all"] && !loading && (swiper.activeIndex >= allQuestions.length - 2 && selectedFilter === 0)) {
            fetchAnsweredUnansweredPolls(true);
        }
        else if (primaryFilterOption === "following" && answeredUnansweredHasMore["following"] && !loading && (swiper.activeIndex >= followingUsersQuestions.length - 2 && selectedFilter === 0)) {
            fetchAnsweredUnansweredPolls(true);
        }
        else if (primaryFilterOption === "madeByYou" && madeByYouHasMore && !loading && (swiper.activeIndex >= madeByYouMap.length - 2 && selectedFilter === 0)) {
            fetchMadeByYou(true);
        }
        else if (topicHasMore && !loading && (swiper.activeIndex >= topicQuestions[selectedTopic]?.length - 2) && selectedFilter === 2) {
            fetchTopicPolls(selectedFilter, selectedTopic, true);
        }
        else if (userPollsHasMore && !loading && (swiper.activeIndex >= userDetailsMap.length - 2) && selectedFilter === 4) {
            fetchUserPolls(true);
        }
    };
    const renderQuestion = (
        question,
        setPollCount,
        questions,
        unansweredQuestions,
        setQuestions,
        setUnansweredQuestions,
        internalLoading = false,
        removeFromUnAnswered = true,
    ) => {
        return (
            <div className="relative flex flex-col w-full h-full gap-3 items-center justify-center">
                <ReactPollComponnet pollQuestion={question} setPollCount={setPollCount} questions={questions} unansweredQuestions={unansweredQuestions} setQuestions={setQuestions} setUnansweredQuestions={setUnansweredQuestions} removeFromUnAnswered={removeFromUnAnswered} internalLoading={internalLoading} followedUsers={followedUsers} setFollowedUsers={setFollowedUsers} />
            </div>
        );
    };

    const getDateString = (dateStr) => {
        // function formatDate(dateStr) {
        const date = new Date(dateStr);
        // Get abbreviated month in lowercase
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day} ${year}`;
    }

    useEffect(() => {
        if (selectedFilter !== 2) {
            setSelectedTopic("");
        }
        setActiveIndex(0);
        if (selectedFilter !== 0) {
            setPrimaryFilterOption("0");
        }
    }, [selectedFilter, selectedTopic, primaryFilterOption]);

    useEffect(() => {
        if (selectedFilter === 4) {
            mixpanel.identify(user.id);
            mixpanel.track('Your_polls', {});
        }
    }, [selectedFilter]);

    useEffect(() => {

        if (selectedFilter === 2) {
            setTopicHasMore(true);
            mixpanel.identify(user.id);
            mixpanel.track('Poll_filters', {
                filter: selectedFilter === 0 ? primaryFilterOption : "Topic",
                ...(selectedFilter === 2 && { topic: selectedTopic })
            });
        }
    }, [selectedTopic]);

    useEffect(() => {
        if (selectedFilter === 0) {
            mixpanel.identify(user.id);
            mixpanel.track('Poll_filters', {
                filter: selectedFilter === 0 ? primaryFilterOption : "Topic",
            });
        }
    }, [primaryFilterOption]);


    const handleCreatePoll = () => {
        mixpanel.identify(user.id);
        mixpanel.track('Poll_addition', {
        });
        setShowCreationPopup(true);
    };

    const HandleProfileEmojiClick = (selectedFilter) => {
        //set url query params user=Y
        navigate("/gk-polls?u=Y");
        // fetchUserPolls() ;
        setSelectedFilter(4);

    };
    const renderCreationPopup = () => {
        return (
            <div className="fixed inset-0 z-[10000] bg-black bg-opacity-50 flex items-center justify-center">
                <PollCreator onClose={() => { setShowCreationPopup(false) }} userId={user.id} setShowPollSubmittedPopup={setShowPollSubmittedPopup} />
            </div>
        );
    };

    const closePopup = () => {
        setShowPollSubmittedPopup(false);
    };

    const renderSuccessDialog = () => {
        return (
            <Dialog
                open={showPollSubmittedPopup}
                onClose={() => {
                    setShowPollSubmittedPopup(false);
                }}
                className="register-success">
                <div className="relative flex flex-col justify-center items-center bg-primary-gradient text-white h-full overflow-hidden px-12 py-10 gap-6">
                    <img src="/Assets/Icons/tickmark.svg" alt="tickmark" />
                    <span className="text-lg md:text-xl font-medium text-center">
                        Thank You!
                    </span>
                    <span className="text-sm text-center">
                        Your poll is submitted to the moderator, you will be informed within a week if it is approved.
                    </span>
                    <div className="flex flex-col items-center justify-center space-x-2 gap-4 ">
                        <AppButton
                            type="button"
                            onClick={closePopup}
                            style={{ minWidth: "100px", height: "30px" }}
                        >
                            Back
                        </AppButton>
                    </div>
                    <Lottie
                        animationData={confettiAnimation}
                        loop={false}
                        className="absolute h-full w-full top-0 z-0"
                        style={{ pointerEvents: "none" }}
                    />
                </div>
            </Dialog>
        );
    };

    const handleGoBack = () => {
        if (isUserPolls) {
            // navigate("/gk-polls")
            window.location.href = "/gk-polls";

        } else {
            navigate("/lobby")
        }
    }

    return (
        <div className="flex flex-col h-full w-full max-w-3xl items-center relative gap-4 bg-[#4e4e4e]">
            <div>
                <ArenaHeader
                    goBack={handleGoBack}
                    headerText="Polling Booth"
                    nonArenaRoute={true}
                    showStreak={true}
                    handleCreatePoll={handleCreatePoll}
                    HandleProfileEmojiClick={HandleProfileEmojiClick}
                    profileEmoji={user.profileEmoji}

                />
            </div>

            {selectedFilter != 4 ?
                <div className="flex flex-col justify-center items-start gap-2 text-[16px] text-white w-full">
                    <div className="flex justify-start items-center gap-4 text-[14px] ml-[7.5%]">
                        <select
                            value={primaryFilterOption !== "all" ? primaryFilterOption : "0"}
                            onChange={(e) => {
                                if (e.target.value !== "0") {
                                    // if(e.target.value === "madeByYou"){
                                    //     setSelectedFilter(1);
                                    // }
                                    // else{
                                    setSelectedFilter(0);
                                    setPrimaryFilterOption(e.target.value);
                                    // }
                                }
                            }}
                            className="text-center rounded-lg p-1 cursor-pointer h-[29px] text-[14px]"
                            style={getStyle(0, primaryFilterOption)}
                        >
                            <option value="0" disabled hidden>
                                Filters
                            </option>
                            {/* <option value="all">All</option> */}

                            <option value="following">Following</option>
                            <option value="madeByYou">Made By You</option>
                            <option value="unanswered">Not Voted</option>
                            <option value="answered">Voted</option>
                        </select>





                        <select
                            value={
                                selectedTopic
                                    ? findIndex(topicsArray, (topic) => topic === selectedTopic) + 1
                                    : 0
                            }
                            onChange={(e) => {
                                // Avoid doing anything if the placeholder is selected
                                if (e.target.value !== "0") {
                                    setSelectedFilter(2);
                                    setSelectedTopic(topicsArray[e.target.value - 1]);
                                }
                            }}
                            className="text-center rounded-lg p-1 cursor-pointer h-[29px] text-[14px]"
                            style={getStyle(2)}
                        >
                            <option value="0" disabled hidden>
                                Topics
                            </option>
                            {topicsArray.map((topic, index) => (
                                <option key={index} value={index + 1}>
                                    {topic}
                                </option>
                            ))}
                        </select>

                        {!(selectedFilter === 0 && primaryFilterOption == "all") && <div className="flex items-center justify-center" onClick={() => { setSelectedFilter(0); setPrimaryFilterOption("all") }}>
                            <img src="/Assets/Icons/filter-reset.svg" alt="tickmark" className="w-[24px] h-[24px]" />
                        </div>}
                    </div>



                </div>
                :
                (!loading && (
                    <div className="flex flex-col justify-center items-center gap-4 text-[16px] text-white w-full">
                        <div className="flex justify-center items-center text-[#ccf900] text-[18px]">
                            YOUR POLLS
                        </div>
                        <div className="flex justify-between items-center w-[85%]">
                            <span>
                                Polls Created: <span className="text-[#ccf900]">{pollsSubmittedByUser}</span>
                            </span>
                            <span>
                                Followers: <span className="text-[#ccf900]">{numberOfFollowers}</span>
                            </span>
                        </div>

                        {userDetailsMap?.length === 0 && (
                            <div className="flex flex-col justify-center items-center mt-8">
                                <div className="flex justify-center items-center text-center mt-8 mx-4">
                                    Submit poll questions for your friends and find them here if approved
                                </div>
                                <div className="flex justify-center items-center mt-8">
                                    <AppButton
                                        type="button"
                                        onClick={handleCreatePoll}
                                        style={{ minWidth: "100px", height: "30px" }}
                                    >
                                        Add Poll
                                    </AppButton>
                                </div>
                            </div>
                        )}
                    </div>
                ))

            }


            {!loading && <div className="">
                <div className="">
                    {selectedFilter === 0 && primaryFilterOption === "all" && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {allQuestions.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        allQuestions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === allQuestions.length - 1,
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 0 && primaryFilterOption === "unanswered" && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {unansweredQuestions.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        unansweredQuestions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === unansweredQuestions.length - 1,
                                        false,

                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 0 && primaryFilterOption === "answered" && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {answeredQuestions.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        answeredQuestions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === answeredQuestions.length - 1,

                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 0 && primaryFilterOption === "following" && followingUsersQuestions?.length > 0 && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {followingUsersQuestions.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        followingUsersQuestions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === followingUsersQuestions.length - 1,

                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 0 && primaryFilterOption === "following" && followingUsersQuestions?.length === 0 && (
                        <div className="flex flex-col justify-center items-center mt-8 gap-4">
                            <div className="flex justify-center items-center text-center mt-8 mx-4 text-white">
                                Follow people to see their poll questions here
                            </div>
                            <AppButton
                                type="button"
                                onClick={() => {
                                    setSelectedFilter(0);
                                    setPrimaryFilterOption("madeByYou");
                                }}
                                style={{ minWidth: "100px", height: "30px" }}
                            >
                                Follow
                            </AppButton>
                        </div>
                    )}

                    {selectedFilter === 0 && primaryFilterOption === "madeByYou" && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {madeByYouMap.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        madeByYouMap,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === madeByYouMap.length - 1,

                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 2 && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {topicQuestions[selectedTopic]?.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        questions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === topicQuestions[selectedTopic].length - 1,

                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {selectedFilter === 4 && (
                        <Swiper
                            observer={true}
                            observeParents={true}
                            direction="vertical"
                            slidesPerView={1.2}
                            centeredSlides={false}
                            loop={false}
                            spaceBetween={20}
                            speed={600}
                            style={{ height: "75vh", width: "100%" }}
                            className="vertical-swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {userDetailsMap.map((question, index) => (
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: activeIndex === index ? "#252D00" : "#799400",
                                        color: "white",
                                        borderRadius: "15px",
                                        width: "85vw",
                                        maxWidth: "650px",
                                    }}
                                >
                                    {renderQuestion(
                                        question,
                                        setPollCount,
                                        questions,
                                        unansweredQuestions,
                                        setQuestions,
                                        setUnansweredQuestions,
                                        internalLoading && index === userDetailsMap.length - 1,

                                    )}
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    )}
                </div>
                {showCreationPopup && renderCreationPopup()}
            </div>}
            {loading && <div className="h-full w-full flex justify-center items-center">
                <Loader /> </div>}

            {renderSuccessDialog()}
        </div>
    );
};

export default GKPolls;