import { json } from "react-router-dom";
import Board from "../../../../../GamesArena/Hangman/Board";
import HangmanBoard from "./Board";
import { useEffect, useState, useRef } from "react";
import { HANGMAN_ALPHABETS } from "../../../../../Constants/GamesArena/Hangman";
import { cloneDeep, get, set } from "lodash";
import LinearTimerBar from "../LinearTimerBar";
import { updateDoc } from "firebase/firestore";
import { InGameNotificationPopup } from "../../InGameNotificationPopup";
import { NEW_FORMAT_TOURNAMENT_GAME_TIMER, NEW_FORMAT_TOURNAMENT_GAME_TRIAL_TIMER } from "../../../../../Constants/Commons";

export const HangmanRoundContainer = ({ currentActiveIndex, currentActiveQuestion, gameState, isTrialGame, questionsList, submitGame, updateGameState, totalQuestions }) => {

    const Timer = isTrialGame ? NEW_FORMAT_TOURNAMENT_GAME_TRIAL_TIMER["HANGMAN"] : NEW_FORMAT_TOURNAMENT_GAME_TIMER["HANGMAN"];
    const timeRef = useRef()
    const MISTAKES_LIMIT = 7;
    const [startTimer, setStartTimer] = useState(false);

    const [scoreData, setScoreData] = useState({ score: 0, attempts: 0, correctAttempts: 0 });
    const scoreRef = useRef(0);
    const currentRoundScoreRef = useRef(0);
    const attemptsRef = useRef(0);
    const correctAttemptsRef = useRef(0);
    const [showInGameNotificationPopup, setShowInGameNotificationPopup] = useState(false);
    const [notificationData, setNotificationData] = useState({ message: "", type: "", score: 0 });

    const [showAnswer, setShowAnswer] = useState(false);
    const [guessedLettersState, setGuessedLettersState] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState("");
    const [response, setResponse] = useState({ keyboard: HANGMAN_ALPHABETS, mistakes: 0, guessedLetters: [], letters: [], length: 0 });
    const [randomLetter, setRandomLetter] = useState("");
    useEffect(() => {
        setResponse({
            keyboard: cloneDeep(HANGMAN_ALPHABETS),
            mistakes: 0,
            guessedLetters: [],
            score: 0,
            length: 0,
            letters: []
        });
        setCorrectAnswer("");
        
        setGuessedLettersState([]);
        setRandomLetter(getRandomLetter(currentActiveQuestion.word));
        makeMove(getRandomLetter(currentActiveQuestion.word), findLetterRowIndex(getRandomLetter(currentActiveQuestion.word)), true);
        setStartTimer(true);
        // makeMove(getRandomLetter(currentActiveQuestion.word), 0);
        currentRoundScoreRef.current = 0;
    }, [currentActiveQuestion]);

    const handleClick = (input, row ) => {
        makeMove(input, row);
    }

    const makeMove = async (input, row, initialMove = false) => {
        const initialValues = {
            keyboard: cloneDeep(HANGMAN_ALPHABETS),
            mistakes: 0,
            guessedLetters: [],
            score: 0,
            length: 0,
            letters: []
        };
        
        // Destructure either from response or the initialValues based on initialMove
        const {
            keyboard: keyboardState,
            mistakes,
            guessedLetters,
            score,
            length,
            letters,
        } = initialMove ? initialValues : response;
        const { word } = currentActiveQuestion;

        // Create a deep copy of the keyboard state
        const newKeyboardState = initialMove ?  cloneDeep(HANGMAN_ALPHABETS)    : cloneDeep(keyboardState);
        const keyFromStateIndex = newKeyboardState[row].findIndex(
            (keyObj) => keyObj.key === input
        );
        newKeyboardState[row][keyFromStateIndex].disabled = true;

        let newMistakes = mistakes;
        let newGuessedLetters = guessedLetters;
        let newLength = length;
        let newLetters = letters ?? [];

        if (!guessedLetters.includes(input)) {
            newGuessedLetters = [...guessedLetters, input];
            setGuessedLettersState(newGuessedLetters);
            let letterCount = 0;
            word.split("").forEach((w) => {
                if (w.toLowerCase() === input.toLowerCase()) {
                    letterCount += 1;
                }
            });
            if (letterCount) {
                newLength += letterCount;
                if (newLength === word.length) {
                    setStartTimer(false);
                    setCorrectAnswer("solved");
                    calculateScoreandUpdateIndb(word, newGuessedLetters, true);
                    setTimeout(() => {
                        setShowInGameNotificationPopup(true);
                    }, 3000);

                    setNotificationData({ message: "You Guessed It", type: "failed", score: currentRoundScoreRef });
                    setTimeout(() => {
                        submitGame(isTrialGame);
                        setShowInGameNotificationPopup(false);
                    }, 8000);
                } 
                newLetters = [...newLetters, { key: input, correct: true }];
            } else {
                newMistakes = mistakes + 1;
                if (newMistakes === MISTAKES_LIMIT) {
                    setStartTimer(false);
                    calculateScoreandUpdateIndb(word, newGuessedLetters);
                    setTimeout(() => {
                        setShowInGameNotificationPopup(true);
                    }, 3000);
                    setNotificationData({ message: "You Missed It", type: "failed", score: currentRoundScoreRef });
                    setTimeout(() => {
                        submitGame(isTrialGame);
                        setShowInGameNotificationPopup(false);
                    }, 8000);
                }
                newLetters = [...newLetters, { key: input, correct: false }];
            }
        }
        setResponse({
            ...response,
            keyboard: newKeyboardState,
            mistakes: newMistakes,
            guessedLetters: newGuessedLetters,
            length: newLength,
            letters: newLetters
        });
    };

    const findLetterRowIndex = (letter) => {
        for (let i = 0; i < HANGMAN_ALPHABETS.length; i++) {
          if (HANGMAN_ALPHABETS[i].some(item => item.key === letter)) {
            return i;
          }
        }
        return -1;
      };

    const getRandomLetter = (currentActiveQuestion) => {
        // Create a frequency map of characters in the string.
        const freq = {};
        for (let char of currentActiveQuestion) {
          freq[char] = (freq[char] || 0) + 1;
        }
      
        const n = currentActiveQuestion.length;
        const mid = Math.floor(n / 2);
      
        // Check if the mid character is unique.
        if (freq[currentActiveQuestion[mid]] === 1) {
          return currentActiveQuestion[mid];
        }
      
        // Search outward: first right, then left, then right further, etc.
        for (let offset = 1; offset < n; offset++) {
          const rightIndex = mid + offset;
          if (rightIndex < n && freq[currentActiveQuestion[rightIndex]] === 1) {
            return currentActiveQuestion[rightIndex];
          }
          
          const leftIndex = mid - offset;
          if (leftIndex >= 0 && freq[currentActiveQuestion[leftIndex]] === 1) {
            return currentActiveQuestion[leftIndex];
          }
        }
      
        // If no unique character is found, return null.
        return currentActiveQuestion[currentActiveQuestion.length - 1];
      };
      

    const timerEndHandler = () => {
        setStartTimer(false);
        setCorrectAnswer("timeup");
        calculateScoreandUpdateIndb(currentActiveQuestion.word);
        setTimeout(() => {
            setShowInGameNotificationPopup(true);
        }, 3000);
        setNotificationData({ message: "Time's Up", type: "failed", score: currentRoundScoreRef });
        setTimeout(() => {
            submitGame(isTrialGame);
            setShowInGameNotificationPopup(false);
        }
            , 8000);
    }

    const renderRightWrongLetterIcon = (letter) => {
        return (
            <div className="grid grid-cols-2 text-white items-center uppercase gap-2 font-bold">
                <div className="pt-[2px]">{letter.key}</div>
                <div className="grid place-items-center">
                    {letter.correct ? (
                        <img
                            alt="tick"
                            src="/Assets/Icons/tick-green-icon.svg"
                            className="w-3 h-3"
                        />
                    ) : (
                        <img
                            alt="cross"
                            src="/Assets/Icons/cross-icon.svg"
                            className="w-3 h-3"
                        />
                    )}
                </div>
            </div>
        );
    };

    const calculateScoreandUpdateIndb = (word, updatedGuessedLetters = guessedLettersState, guessed = false) => {

        const uniqueAlphabets = new Set(word.toLowerCase().split(""));
        const uniqueAlphabetsLength = uniqueAlphabets.size;
        let currentRoundScore = 0;

        //find number of common alphabets between guessedLetters and uniqueAlphabets
        const commonAlphabets = updatedGuessedLetters.filter((letter) => uniqueAlphabets.has(letter));

        const percentage = ((commonAlphabets.length-1) / (uniqueAlphabetsLength-1)) * 100;
        if (percentage === 100) {
            currentRoundScore = 5;
        } else if (percentage >= 80) {
            currentRoundScore = 3;
        } else if (percentage >= 50) {
            currentRoundScore = 2;
        } else if (percentage >= 20) {
            currentRoundScore = 1;
        }
        else {
            currentRoundScore = 0;
        }
        const totalScore = scoreRef.current + currentRoundScore;
        currentRoundScoreRef.current = currentRoundScore;
        scoreRef.current = totalScore;
        const totalAttempts = attemptsRef.current + updatedGuessedLetters.length;
        attemptsRef.current = totalAttempts;
        const correctAttempts = correctAttemptsRef.current + commonAlphabets.length;
        correctAttemptsRef.current = correctAttempts;

        const attemptedWords = gameState.attemptedWords ?? [];
        attemptedWords.push(JSON.stringify(currentActiveQuestion));
        const dateToUpdate = {
            attemptedWords: attemptedWords,
            score: totalScore,
            attempts: totalAttempts,
            correctAttempts: correctAttempts,
            wordResponses: [...gameState.wordResponses ?? [], { word: word, hint: currentActiveQuestion.hint, guessedLetters: updatedGuessedLetters, timeTaken: ((100 - timeRef.current) / 100) * Timer, correct: guessed }]
        }
        if (!isTrialGame) {
            updateGameState(dateToUpdate);
        }
    }

    const renderBoard = (gameState) => {
        return (
            <div className="memory-game-board h-full relative">
                <div className="flex flex-col items-center gap-2 absolute right-4 top-0 mt-[95px] text-primary-yellow text-sm">
                    Attempts
                    <div>
                        
                        {response?.letters?.map((letter) => {
                            if(letter.key != randomLetter)
                            return renderRightWrongLetterIcon(letter, randomLetter);
                        })}
                    </div>
                </div>

                <div className="flex items-center justify-between text-white text-xl">
                    <div className="w-1/3"></div>
                    <div className="w-1/3 text-center">
                        <span>Score : {scoreRef.current}</span>
                    </div>
                    <div className="w-1/3 text-right mr-3">
                        <span>{currentActiveIndex + 1}/{totalQuestions}</span>
                    </div>
                </div>


                <LinearTimerBar
                    totalDuration={Timer}
                    startTimer={startTimer}
                    isSelfTimer
                    reset={startTimer}
                    timerEnd={timerEndHandler}
                    timeRef={timeRef}
                />
                <HangmanBoard handleClick={handleClick} gameState={gameState} showAnswer={showAnswer} currentActiveQuestion={currentActiveQuestion} response={response} setResponse={setResponse} winner={correctAnswer} />
            </div>
        );
    }


    return (
        <div className="flex flex-col items-center gap-4 w-[100vw] h-[calc(100vh-160px)] bg-black relative">
            <div className="flex flex-col items-center gap-4 w-full h-full">
                <div className="flex items-center gap-4">
                </div>
                <div className="w-full">
                    {renderBoard(gameState)}
                </div>
            </div>
            {showInGameNotificationPopup && <InGameNotificationPopup message={notificationData.message} type={notificationData.type} score={notificationData.score} showTimer={currentActiveIndex !== totalQuestions - 1} PopupDuration={5} showTotalScore={false} word={currentActiveQuestion.word} />}
        </div>
    );
}